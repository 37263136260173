import React from 'react';
import { Typography, Space, Row, Col } from 'antd';
import moment from 'moment';

import { List } from './MesssageSettings';
import { ResubscribeToListsButton } from './ResubscribeAllPracticalEmailBasedInformationButton';

import { InformationCard } from '@/react/people/components/people-contact/InformationCard';
import { CdFilterIcon } from '@/react/shared/components/Icons';
import { I18nService } from '@/react/services/I18nService';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

export const FilterSettings = ({
  contactId,
  contactName,
  communicateFromFilters,
  unSubscriptions,
  updateReceiveCommunicationFromFilters,
}: {
  contactId: number;
  contactName: string;
  communicateFromFilters: boolean;
  unSubscriptions: List[];
  updateReceiveCommunicationFromFilters: () => void;
}) => (
  <InformationCard
    title={I18nService.getString('Filters')}
    canView={true}
    icon={<CdFilterIcon />}
  >
    <Row gutter={[0, 16]}>
      {!communicateFromFilters && (
        <Col span={24}>
          <Space
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography.Text>
              {I18nService.getString(
                'All filters: All practical email based information'
              )}
            </Typography.Text>
            <ResubscribeToListsButton
              personName={contactName}
              updateReceiveCommunicationFromFilters={
                updateReceiveCommunicationFromFilters
              }
              communicateFromFilters={communicateFromFilters}
              contactId={contactId}
              buttonText={I18nService.getString('Opt-in')}
            />
          </Space>
        </Col>
      )}
      {communicateFromFilters &&
        unSubscriptions.length > 0 &&
        unSubscriptions.map((list) => (
          <Col span={24} key={list.listId}>
            <Space
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <CdVerticalSpace size={2}>
                <Typography.Text>{list.listName}</Typography.Text>
                <Typography.Text style={{ fontSize: '12px' }}>
                  {I18nService.getString('Opted out {{dateTime}}', {
                    dateTime: moment(list.unsubscribedAt).format('lll'),
                  })}
                </Typography.Text>
              </CdVerticalSpace>

              <ResubscribeToListsButton
                personName={contactName}
                communicateFromFilters={communicateFromFilters}
                list={list}
                contactId={contactId}
                buttonText={I18nService.getString('Opt-in')}
              />
            </Space>
          </Col>
        ))}
      {communicateFromFilters && unSubscriptions.length === 0 && (
        <Col span={24}>
          <Space
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {I18nService.getString('No opt-outs')}
          </Space>
        </Col>
      )}
    </Row>
  </InformationCard>
);
