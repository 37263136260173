import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { MessageSettings } from '@/react/people/components/MesssageSettings';
import { FilterSettings } from '@/react/people/components/FilterSettings';
angular
  .module('cdApp.people')
  .component(
    'cdrMessageSettings',
    react2angular(
      sharedContext.use(MessageSettings),
      [
        'smsNotification',
        'emailNotification',
        'canView',
        'contactName',
        'updateMessageSettings',
      ],
      []
    )
  );

angular
  .module('cdApp.people')
  .component(
    'cdrFilterSettings',
    react2angular(sharedContext.use(FilterSettings), [
      'contactId',
      'contactName',
      'unSubscriptions',
      'communicateFromFilters',
      'updateReceiveCommunicationFromFilters',
    ])
  );
