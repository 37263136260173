import React from 'react';
import { Card, Input, Spin, Typography } from 'antd';

import {
  createCdModal,
  ModalType,
} from '../../../shared/components/cd-modal-new/CdModal2';
import { I18nService } from '../../../services/I18nService';
import { useApproveDeclineEnquiryModal } from '../hooks/use-approve-decline-enquiry-modal';
import NotificationService from '../../../services/NotificationService';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';

import { FormSubmissionViewer } from './FormSubmissionViewer';
import { DateIntervalRender } from './DateIntervalRender';
import { EmailAlert } from './EmailAlert';

export interface ApproveDeclineEnquiryModalProps {
  respond: 'accepted' | 'rejected';
  id: string;
}

const { Title } = Typography;

export const showApproveDeclineEnquiryModal =
  createCdModal<ApproveDeclineEnquiryModalProps>(
    ({ setModalProps, respond, id }) => {
      const { isLoading, enquiry, message, setMessage, bookingLink } =
        useApproveDeclineEnquiryModal({ setModalProps, respond, id });

      return (
        <Spin spinning={isLoading}>
          {enquiry && (
            <CdVerticalSpace size="middle">
              <EmailAlert
                type={respond === 'accepted' ? 'success' : 'danger'}
                title={
                  respond === 'accepted'
                    ? I18nService.getString('Your enquiry has been accepted')
                    : I18nService.getString('Change of date necessary')
                }
                buttonText={
                  respond !== 'accepted' &&
                  I18nService.getString('Make another enquiry')
                }
                href={respond !== 'accepted' && bookingLink}
              >
                <Input.TextArea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  autoSize={true}
                  maxLength={500}
                  showCount
                />
              </EmailAlert>

              {respond === 'accepted' &&
                enquiry.taxonomy.config.automaticFollowUpForm && (
                  <EmailAlert
                    type="warning"
                    title={I18nService.getString('Next step')}
                    buttonText={I18nService.getString('Open form')}
                    onClick={() =>
                      NotificationService.notifyInfo(
                        I18nService.getString(
                          'This is a preview, you cannot answer the form for the requester'
                        )
                      )
                    }
                  >
                    {I18nService.getString(
                      'We require a few more details to finalize your booking. Please fill out the form below at your earliest convenience to help us move forward smoothly.'
                    )}
                  </EmailAlert>
                )}

              <Card>
                <Title level={2} style={{ marginTop: 0 }}>
                  {I18nService.getString('Copy of your Enquiry')}
                </Title>
                <FormSubmissionViewer
                  submissionData={[
                    {
                      label: I18nService.getString('Date'),
                      value: (
                        <DateIntervalRender
                          startDate={enquiry.startDate}
                          endDate={enquiry.endDate}
                          type="inline"
                        />
                      ),
                    },
                    ...enquiry?.submissionData,
                  ]}
                />
              </Card>
            </CdVerticalSpace>
          )}
        </Spin>
      );
    },
    {
      width: 600,
      title: I18nService.getString('Preview response'),
      okText: I18nService.getString('Submit response'),
      modalType: ModalType.MODAL,
    }
  );
