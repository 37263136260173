import { Button, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import {
  CdApproveEnquiryIcon,
  CdDeclineEnquiryIcon,
  CdExclamationTriangleIcon,
  colors,
  IconType,
  lightened_colors,
} from '../../../shared/components/Icons';

interface EmailAlertProps {
  title?: string;
  type?: IconType;
  children?: React.ReactNode;
  buttonText?: string;
  onClick?: () => void;
  href?: string;
}

const { Title, Paragraph } = Typography;

export const EmailAlert = (props: EmailAlertProps) => (
  <StyledCard type={props.type}>
    {props.title && (
      <Title
        style={{ color: colors[props.type], fontWeight: 500, marginTop: 0 }}
      >
        <Space size="middle">
          {icons[props.type]}
          {props.title}
        </Space>
      </Title>
    )}
    {props.children && <Paragraph>{props.children}</Paragraph>}
    {props.buttonText && (
      <Button onClick={props.onClick} href={props.href} target="_blank">
        {props.buttonText}
      </Button>
    )}
  </StyledCard>
);

const icons = {
  success: <CdApproveEnquiryIcon type="success" />,
  danger: <CdDeclineEnquiryIcon type="danger" />,
  warning: <CdExclamationTriangleIcon type="warning" />,
};

const StyledCard = styled.div<{ type: IconType }>`
  padding: 16px;
  border-left-width: 4px;
  border-left-style: solid;
  border-radius: 8px;
  border-color: ${(props) => colors[props.type]};
  background-color ${(props) => lightened_colors[props.type]};
`;
